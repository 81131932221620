<template>
  <XyeaFormEdit
    icon="paperclip"
    url="attachments/"
    :entityName="entityName"
    :form="form"
    :id="form.attachmentId"
    @close="$emit('close')"
    @dataSaved="refresh"
    :disabled="readonly"
    :dialog="true"
    :loadOnOpen="false"
  >
    <b-field horizontal>
      <div class="level">
        <file-picker
          v-if="!readonly"
          :id="form.attachmentId"
          :entityId="entityId"
          :imageType="attachmentType"
          @new-image="newAttachment"
          v-focus
        />
        <b-navbar-item v-if="!readonly" :href="form.fileUrl" target="_blank" class="level-item" expanded>
          <b-icon v-if="form.fileName" class="is-large" icon="paperclip" />{{ form.fileName }}
        </b-navbar-item>
      </div>
    </b-field>
    <iframe
      class="mb-2"
      v-if="showOfficeEmbdedFile"
      :src="'https://view.officeapps.live.com/op/embed.aspx?wdStartOn=1&amp;wdPrint=0&amp;wdEmbedCode=0&amp;src=' + form.fileUrl"
      width="700px"
      height="288px"
      frameborder="0"
    />
    <img v-if="showImage" class="embeded" :src="form.fileUrl" />
    <embed v-if="showPdf" class="embeded" :src="form.fileUrl" type="application/pdf" width="500" height="375" />

    <b-field label="Name" horizontal>
      <b-input v-model="form.name" placeholder="Name" required :disabled="readonly" />
    </b-field>
    <b-field label="Notes" horizontal>
      <b-input v-model="form.notes" placeholder="Notes" :disabled="readonly" />
    </b-field>
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import PermissionsEnum from "@/enums/permissions";
import FilePicker from "@/components/FilePicker";
import AttachmentType from "@/enums/imageType";
import FileType from "@/enums/fileType";

export default {
  components: { XyeaFormEdit, FilePicker },
  props: ["attachment", "entityId", "attachmentType"],

  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject()
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.ResidentUpdate);
    },
    fileType() {
      return FileType.getFileType(this.form.fullFileName);
    },
    viewerType() {
      return FileType.ViewerType[this.fileType];
    },
    showOfficeEmbdedFile() {
      return this.viewerType === FileType.ViewerTypeEnum.Office;
    },
    showImage() {
      return this.viewerType === FileType.ViewerTypeEnum.Image;
    },
    showPdf() {
      return this.viewerType === FileType.ViewerTypeEnum.Pdf;
    },
    entityName() {
      return AttachmentType.EnityName[this.attachmentType];
    }
  },
  methods: {
    getClearFormObject() {
      var newForm = {
        attachmentId: 0,
        name: null,
        fullFileName: null,
        fileName: null,
        fileUrl: null,
        who: null,
        when: null,
        notes: null,
        attachmentType: this.attachmentType
      };
      this.setAttachmentEnityId(newForm);
      return newForm;
    },
    refresh() {
      this.$emit("refresh");
    },
    newAttachment(newImage) {
      this.form.fullFileName = newImage.fullFileName;
      this.form.fileName = newImage.fileName;
      this.form.fileUrl = newImage.url;

      if (!this.form.name) this.form.name = newImage.fileNameNoExtension;
    },
    setAttachmentEnityId(form) {
      form.residentId = this.attachmentType === AttachmentType.Type.ResidentAttachment ? this.entityId : null;
      form.riskId = this.attachmentType === AttachmentType.Type.RiskAttachment ? this.entityId : null;
      form.controlId = this.attachmentType === AttachmentType.Type.ControlAttachment ? this.entityId : null;
      form.actionId = this.attachmentType === AttachmentType.Type.ActionAttachment ? this.entityId : null;
      form.woundId = this.attachmentType === AttachmentType.Type.WoundImage ? this.entityId : null;
    }
  },
  mounted() {
    if (this.attachment?.attachmentId) {
      this.form = { ...this.attachment };
      this.setAttachmentEnityId(this.form);
    }
  }
};
</script>

<style scoped>
.embeded {
  max-height: 280px;
}
</style>
