<template>
  <div class="column">
    <b-collapse class="card" animation="slide" :aria-id="title" :open="open" @update:open="v => $emit('update:open', v)">
      <template v-slot:trigger="props">
        <div class="card-header" role="button" :aria-controls="title">
          <p class="card-header-title">
            {{ title }}
          </p>
          <div v-if="headerIcons" class="is-flex">
            <a v-for="icon in headerIcons" :key="icon.name" href="#" class="card-header-icon" @click.stop="headerIconsClick(icon.name)">
              <b-icon :icon="icon.icon" :title="icon.title" />
            </a>
          </div>
          <ClickIcon v-if="showAdd" icon="add" :title="'Add New ' + entityName" @click="$emit('add')" />
          <ClickIcon v-if="showEdit" icon="edit" :title="'Edit ' + entityName" @click="$emit('edit')" />
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <slot />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import IconsMixin from "@/mixins/iconsMixin.js";
import ClickIcon from "@/components/ClickIcon";

export default {
  mixins: [IconsMixin],
  components: { ClickIcon },
  props: {
    open: { type: Boolean, required: true },
    title: { type: String, required: true },
    name: { type: String }, // singular name used in Add icon title
    showAdd: { type: Boolean },
    showEdit: { type: Boolean },
    headerIcons: { type: Array }
  },
  computed: {
    entityName() {
      return this.name || this.title;
    }
  },
  methods: {
    headerIconsClick(iconName) {
      this.$emit("header-icons-click", iconName);
    }
  }
};
</script>
