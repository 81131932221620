<template>
  <b-field class="file">
    <b-upload v-model="file" :accept="accept" @input="upload">
      <b-button v-if="uploading" class="button is-primary" :loading="uploading" />
      <a v-else class="button is-primary">
        <b-icon icon="upload" />
      </a>
    </b-upload>
  </b-field>
</template>

<script>
import alerts from "../common/alerts";
import ImageTypeAEnum from "../enums/imageType";

export default {
  name: "FilePicker",
  props: {
    accept: {
      type: String,
      default: null
    },
    id: { type: Number, required: true },
    entityId: { type: Number, required: false },
    imageType: { type: Number, required: true }
  },
  data() {
    return {
      file: null,
      uploadPercent: 0,
      uploading: false
    };
  },
  methods: {
    async upload(file) {
      this.uploading = true;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", this.id);
      if (this.entityId) formData.append("entityId", this.entityId);

      await this.$http
        .post("imageupload/" + ImageTypeAEnum.ActionUrl[this.imageType], formData)
        .then(r => {
          this.$emit("new-image", r.data);
        })
        .catch(e => {
          alerts.showErrorAlert(e, "Error uploading file");
        })
        .finally(() => (this.uploading = false));
    },
    progressEvent(progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    }
  }
};
</script>
