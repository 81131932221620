const FileTypeEnum = {
  Unknown: 0,
  Word: 1,
  Excel: 2,
  Powerpoint: 3,
  Pdf: 4,
  Image: 5
};

const ViewerTypeEnum = {
  None: 0,
  Office: 1,
  Pdf: 2,
  Image: 3
};

export default {
  FileTypeEnum: FileTypeEnum,
  ViewerTypeEnum: ViewerTypeEnum,
  getFileType(filename) {
    const extention = filename?.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;

    switch (extention) {
      case "doc":
      case "docx":
        return FileTypeEnum.Word;
      case "xls":
      case "xlsx":
        return FileTypeEnum.Excel;
      case "ppt":
      case "pptx":
        return FileTypeEnum.Powerpoint;
      case "pdf":
        return FileTypeEnum.Pdf;
      case "bmp":
      case "gif":
      case "jpg":
      case "jpeg":
      case "png":
      case "tiff":
      case "svg":
        return FileTypeEnum.Image;
      default:
        return FileTypeEnum.Unknown;
    }
  },
  ViewerType: [
    ViewerTypeEnum.None,
    ViewerTypeEnum.Office,
    ViewerTypeEnum.Office,
    ViewerTypeEnum.Office,
    ViewerTypeEnum.Pdf,
    ViewerTypeEnum.Image
  ]
};
