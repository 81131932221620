<template>
  <CollapseCard title="Attachments" name="Attachment" :open.sync="attachmentsOpen" :showAdd="!readonly" @add="addAttachment">
    <ContextTable
      :tableData="attachments"
      sortField="order"
      v-model="selectedAttachment"
      :canEdit="!readonly"
      @contextDefaultClicked="editAttachment"
      @menu-clicked="editAttachment"
      :isLoading="isLoadingAttachments"
      smallNoData
    >
      <b-table-column label="Name" field="name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="File Name" field="fileName" sortable v-slot="props">
        {{ props.row.fileName }}
      </b-table-column>
      <b-table-column label="Notes" field="notes" sortable v-slot="props">
        {{ props.row.notes }}
      </b-table-column>
      <b-table-column label="Modified" field="modified" sortable v-slot="props">
        {{ shortFormat(props.row.modified) }}
      </b-table-column>
      <b-table-column label="By" field="who" sortable v-slot="props">
        {{ props.row.who }}
      </b-table-column>
      <b-table-column custom-key="actions" v-slot="props">
        <delete-object
          v-if="isAdmin"
          entity="Attachment"
          :deleteName="props.row.name"
          :deleteId="props.row.attachmentId"
          url="attachments"
          @deleted="deletedAttachment"
        />
      </b-table-column>
    </ContextTable>
  </CollapseCard>
</template>

<script>
import AttachmentDialog from "@/components/dialogs/AttachmentDialog.vue";
import CollapseCard from "@/components/CollapseCard";
import ContextTable from "@/components/ContextTable";
import DeleteObject from "@/components/DeleteObject.vue";
import Dates from "@/common/dates";
import { mapGetters } from "vuex";

export default {
  components: { CollapseCard, ContextTable, DeleteObject },
  props: {
    entityId: { validator: prop => typeof prop === "number" || prop === null || prop === undefined, required: false, default: null },
    attachmentType: { type: Number, required: true },
    readonly: { type: Boolean }
  },
  data() {
    return {
      selectedAttachment: {},
      isLoadingAttachments: false,
      attachmentsOpen: false,
      attachments: []
    };
  },
  watch: {
    attachmentsOpen(open) {
      if (open && !this.attachments.length) this.refreshAttachments();
    }
  },
  computed: {
    ...mapGetters(["isAdmin"])
  },
  methods: {
    shortFormat(date) {
      return Dates.shortFormat(date);
    },
    refreshAttachments() {
      this.isLoadingAttachments = true;
      this.$http
        .get("attachments/" + this.entityId + "?attachmentType=" + this.attachmentType)
        .then(r => (this.attachments = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Attachments"))
        .finally(() => (this.isLoadingAttachments = false));
    },
    addAttachment() {
      this.selectedAttachment = {};
      if (!this.entityId) {
        this.$alerts.showAlert("Record not saved", "Please save record first before adding Attachments");
        // entity needs saved first - not fully working
        // this.$emit("saveFirst");
        return;
      }
      this.editAttachment();
    },
    editAttachment() {
      this.$buefy.modal.open({
        parent: this,
        component: AttachmentDialog,
        hasModalCard: true,
        props: { attachment: this.selectedAttachment, entityId: this.entityId, attachmentType: this.attachmentType },
        events: { refresh: this.refreshAttachments }
      });
    },
    deletedAttachment(id) {
      this.attachments = this.attachments.filter(r => r.attachmentId !== id);
    }
  }
};
</script>
