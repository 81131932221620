export default {
  Type: {
    Profile: 1,
    Resident: 2,
    AdvCarePlan: 3,
    ResidentAttachment: 4,
    RiskAttachment: 5,
    ControlAttachment: 6,
    ActionAttachment: 7,
    WoundImage: 8
  },
  ActionUrl: [
    "",
    "profile",
    "UploadResidentImage",
    "UploadAdvCarePlan",
    "UploadResidentAttachment",
    "UploadRiskAttachment",
    "UploadControlAttachment",
    "UploadActionAttachment",
    "UploadWoundImage"
  ],
  EnityName: [
    "",
    "Profile",
    "Resident Image",
    "Advanced Care Plan",
    "Resident Attachment",
    "Risk Attachment",
    "Control Attachment",
    "Action Attachment",
    "Wound Image"
  ]
};
